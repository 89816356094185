<template>
  <div>
    <v-breadcrumbs v-if="!isEmbeddedInApp" :items="breadcrumbItems"/>

    <v-card v-if="org" :elevation="isEmbedded ? 0 : 2">
      <GroupHeader :group="org" />

      <v-alert v-if="isConnected" tile prominent type="success">
        <p>{{$t('events.welcome.joined-message', {event: org.name})}}</p>
        <p>
          <span v-if="status.verification_code">{{$t('events.welcome.joined-code', {code: status.verification_code})}}</span>
        </p>
        <p v-if="status.reg_name">
          <i18n path="events.welcome.reg-name">
            <strong >{{status.reg_name}}</strong>
          </i18n>
          </p>
      </v-alert>

      <div v-if="isConnected">
        <v-card-text>
          View the events and challenges which are hosted by {{org.name}}.
        </v-card-text>
        <v-card-text>
          <v-btn :color="org.color||'primary'" :dark="$helpers.isDarkColor(org.color || 'primary')" :to="{ name: 'organization', params: {id: org.id}}" class="mr-2">View Organization</v-btn>
          <v-btn text class="mr-2" @click="disconnect">Disconnect</v-btn>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import groupService from "@/services/groupService";
import GroupHeader from '@/components/GroupHeader.vue';
import EventGrid from '@/components/EventGrid.vue';
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import VueMarkdown from '@/components/VueMarkdown.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Event",
  components: {
    GroupHeader,
    VueMarkdown,
    EventGrid,
  },
  props: {
  },
  data() {
    return {
      org: null,
      status: null,
      profile: null,
    };
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadData(to.params.id);
    next();
  },

  async mounted() {
    await this.loadData(this.$route.params.id);
    
    EventBus.$on('login-state-change', async user => {
      //console.log('LOGIN CHANGED', user, this);
      await this.getGroupStatus();
    });
    await this.getGroupStatus();
  },

  methods: {
      
    async getGroupStatus() {
      if (this.user) {
        var verificationCode = this.$route.query.verificationCode;
        var response = await profileService.getGroupStatus(this.org.id);
        this.status = response.data;
        this.profile = (await profileService.get(/*full:*/false)).data;

        if (this.status && this.status.verification_required){
          this.$router.replace({ name: "organizationJoin", params: { id: this.org.id}, query: {verificationCode: verificationCode} });
          return;
        }
      }
    },
    
    async loadData(id) {
      var response = await groupService.get(id);
      this.org = response.data;
      var meta = {
        title: this.org.name + ' on ' + tenant.name,
        description: this.org.description,
        image: this.org.img,
      };
      EventBus.$emit('page-header-change', meta);
    },
    
    async disconnect() {
      if (confirm('Are you sure you want to disconnect from this organization?')) {
        await profileService.disconnectGroup(this.org.id);
        this.$router.replace({ name: "profile" });
      }
    },
  },
  computed: {
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    isConnected() {
      return this.status && this.status.connected;
    },
    breadcrumbItems() {
      return !this.org ? [] : [
        { text: this.org.name, exact: true, to: { name: 'organization', params: {id: this.org.id}} },
        { text: 'Welcome', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">

</style>

